<template>
    <div>
        <section class="header-bg" style="">
        </section>

        <section class="container-1200 se27">
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                <el-menu-item index="1">Contrimetrics</el-menu-item>
                <el-menu-item index="2">Publications</el-menu-item>
                <el-menu-item index="3">Team</el-menu-item>
                <el-menu-item index="4">Careers</el-menu-item>
                <el-menu-item index="5">Contact</el-menu-item>
            </el-menu>

            <div>
                <introductionVue></introductionVue>
            </div>

            <div>
                <approachVue></approachVue>
            </div>

            <div>
                <whyVue></whyVue>
            </div>

            <div>
                <scholarlyVue></scholarlyVue>
            </div>

            <div>
                <citationVue></citationVue>
            </div>

            <div>
                <assessmentVue></assessmentVue>
            </div>

            <div>
                <futureVue></futureVue>
            </div>
        </section>
    </div>
</template>

<script>
import introductionVue from './aboutNew/introduction.vue';
import approachVue from './aboutNew/approach.vue';
import whyVue from './aboutNew/why.vue';
import scholarlyVue from './aboutNew/scholarly.vue';
import citationVue from './aboutNew/citation.vue';
import assessmentVue from './aboutNew/assessment.vue';
import futureVue from './aboutNew/future.vue';

export default {
    components: {
        introductionVue,
        approachVue,
        whyVue,
        scholarlyVue,
        citationVue,
        assessmentVue,
        futureVue
    },
    data() {
        return {
            activeIndex: '1',
        }
    },
    methods: {
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        }
    }
}
</script>

<style lang='scss'>
.se27 {
    margin-top: 40px;
}

@media (max-width: 768px) {
    .el-menu-demo {
        .el-menu-item {
            font-size: 14px;
            padding: 0 10px;
        }
    }

    .se27 {
        margin-top: 20px;
    }
}

@media (max-width: 410px) {
    .el-menu-demo {
        .el-menu-item {
            padding: 0 6px;
        }
    }
}
</style>